<script setup>
import cloneDeep from 'lodash/cloneDeep';
import debounce from 'lodash/debounce';
import each from 'lodash/each';
import filter from 'lodash/filter';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';
import isNull from 'lodash/isNull';
import join from 'lodash/join';
import map from 'lodash/map';
import reduce from 'lodash/reduce';
import set from 'lodash/set';
import { computed, ref, watch } from 'vue';
import moment from 'moment-timezone';
import {
  DATE_FORMAT, DELAY, everyValue, exportFile,
} from '@emobg/web-utils';
import TableFiltersComponent from '@/components/Table/TableFiltersComponent';
import TablePaginatorComponent from '@/components/Table/TablePaginatorComponent';
import TableSearchBoxComponent from '@/components/Table/TableSearchBoxComponent';
import TableComponent from '@/components/Table/TableComponent';
import {
  FILTER_TYPE_MAP,
  TYPES as FILTER_TYPES,
  FILTERS,
} from '@/components/Table/table.const';
import {
  createFiltersApplied,
  getCsvContent,
  getDataApplyingFilters,
  getDefaultFilterValue,
  getFilterOptionsFromData,
  getFilterOptionsFromEndpoint,
} from '@/components/Table/table.utils';

const props = defineProps({
  data: {
    type: [Array, Object],
    default: () => [],
  },
  filters: {
    type: Array,
    default: () => [],
  },
  schema: {
    type: Array,
    default: () => [],
  },
  searchable: {
    type: Boolean,
    default: false,
  },
  actions: {
    type: Array,
    default: () => [],
  },
  filtersVisible: {
    type: Boolean,
    default: false,
  },
  exportable: {
    type: Boolean,
    default: false,
  },
  exportName: {
    type: String,
    default: '',
  },
  /**
   * If set it will use this schema to export data into csv instead of the table schema.
   * So it can be used to export different information than displayed in the table.
   * [
   *  {
   *    header: String. Title column.
   *    template: Function|String. Value of the column.
   *  },
   * ]
   */
  exportSchema: {
    type: Array,
    default: undefined,
  },
  /**
   * Data prop should return this object structure if isApi is set:
   * {
   *   // The array of data to show.
   *   data: [],
   *
   *   // Array of the values with counts of attributes specified by BE.
   *   filters: [
   *     {
   *       attribute: 'attribute_1',
   *       values: [
   *         { label: 'value of attribute 1', count: 10 }
   *       ],
   *     },
   *   ],
   *
   *   // Total records
   *   total: 100,
   *
   *   // Total pages
   *   lastPage: 15,
   *
   *   // Current page
   *   currentPage: 1,
   *
   *   // Number of items per page
   *   perPage: 10,
   * },
   */
  isApi: {
    type: Boolean,
    default: false,
  },
  isLoading: {
    type: Boolean,
    default: false,
  },
  perPage: {
    type: Number,
    default: 25,
  },
});

const emits = defineEmits(['api:request']);

const getData = (newData) => (props.isApi ? get(newData, 'data', []) : newData);

const search = ref('');
const areFiltersVisible = ref(props.filtersVisible);
const internalData = ref(getData(props.data));
const filtersApplied = ref(createFiltersApplied(props.filters));
const page = ref(1);
const recordsPerPage = ref(props.isApi ? get(props.data, 'perPage', props.perPage) : props.perPage);

const filtersCount = computed(() => reduce(
  filtersApplied.value,
  (result, { value }) => {
    const filtersWithValue = filter(value, filterItem => !!filterItem);
    return result + filtersWithValue.length;
  },
  0,
));
const dataFiltered = computed(() => {
  if (props.isApi) {
    return internalData.value;
  }

  return getDataApplyingFilters({
    data: internalData.value,
    filtersToApply: filtersApplied.value,
    search: search.value,
  });
});

const totalRecords = computed(() => {
  if (props.isApi) {
    return get(props.data, 'total', 0);
  }

  return dataFiltered.value.length;
});
const totalPages = computed(() => {
  if (props.isApi) {
    return get(props.data, 'lastPage', 0);
  }

  return Math.ceil(totalRecords.value / recordsPerPage.value);
});
const dataToShow = computed(() => {
  if (props.isApi) {
    return internalData.value;
  }
  const startToSlice = (page.value - 1) * recordsPerPage.value;
  const endToSlice = page.value * recordsPerPage.value;
  return dataFiltered.value.slice(
    startToSlice < 0 ? 0 : startToSlice,
    endToSlice > totalRecords.value ? totalRecords.value : endToSlice,
  );
});

/**
 * To retrieve the options to show on the filter.
 * If "request" is set it will get the options from the endpoint.
 * Else will create the options using the actual data.
 */
function getFilterOptions(filterSchemaItem, filterSchemaIndex) {
  if (props.isApi) {
    return getFilterOptionsFromEndpoint(filterSchemaItem, props.data);
  }

  return getFilterOptionsFromData(filterSchemaItem, getDataApplyingFilters({
    data: internalData.value,
    filtersToApply: filtersApplied.value,
    filtersIndexToOmit: filterSchemaIndex,
    search: search.value,
  }));
}

const internalFilters = computed(() => map(props.filters, (filterSchemaItem, filterSchemaIndex) => {
  const filterSchemaItemRefined = {
    ...filterSchemaItem,
  };
  if (FILTER_TYPE_MAP[filterSchemaItem.type] === FILTER_TYPES.disjunctive) {
    set(filterSchemaItemRefined, 'props.options', getFilterOptions(filterSchemaItem, filterSchemaIndex));
  }

  return filterSchemaItemRefined;
}));

const exportCsv = () => {
  const csvContent = getCsvContent(props.exportSchema || props.schema, dataFiltered.value);
  exportFile(csvContent, `${props.exportName}_${moment().format(DATE_FORMAT.defaultExtended)}.csv`);
};

const actionsMerged = computed(() => {
  const exportCsvAction = {
    label: 'Export to CSV',
    labelClass: 'emobg-font-weight-semibold',
    action: exportCsv,
  };

  return [
    ...(props.exportable ? [exportCsvAction] : []),
    ...props.actions,
  ];
});

const getQueryParams = () => {
  const filtersQuery = {};
  each(filtersApplied.value, filterAppliedItem => {
    if (filterAppliedItem.filterType === FILTERS.datesRange && everyValue(filterAppliedItem.value, isNull)) {
      return;
    }

    const sanitizedFilterAppliedItemValues = map(
      filterAppliedItem.value,
      filterAppliedValue => filterAppliedValue || String(filterAppliedValue),
    );
    const joinedFilterAppliedItemValues = join(sanitizedFilterAppliedItemValues, ',');

    if (isEmpty(joinedFilterAppliedItemValues)) {
      return;
    }

    set(
      filtersQuery,
      filterAppliedItem.attributeName,
      joinedFilterAppliedItemValues,
    );
  });

  return {
    page: page.value,
    perPage: recordsPerPage.value,
    filters: filtersQuery,
  };
};

const setSingleFiltersApplied = (newFilterApplied) => {
  const {
    index,
    value,
  } = newFilterApplied;

  if (!Number.isNaN(index)) {
    const newFiltersApplied = cloneDeep(filtersApplied.value);
    set(newFiltersApplied, `[${index}].value`, [...value]);
    filtersApplied.value = newFiltersApplied;
  }
};

const clearFiltersApplied = () => {
  const newFiltersApplied = cloneDeep(filtersApplied.value);
  each(newFiltersApplied, filterAppliedItem => {
    set(filterAppliedItem, 'value', getDefaultFilterValue(filterAppliedItem.filterType));
  });
  filtersApplied.value = newFiltersApplied;
};

const setPage = (newPage) => {
  if (newPage < 1) {
    page.value = 1;
  }
  if (newPage > totalPages.value) {
    page.value = totalPages.value;
  }

  page.value = newPage;

  if (props.isApi) {
    emits('api:request', getQueryParams());
  }
};

const setSearchDebounced = debounce(newSearch => {
  search.value = newSearch;
}, DELAY.short);

watch(
  () => props.data,
  (newData, oldData) => {
    if (!isEqual(oldData, newData)) {
      internalData.value = getData(newData);
    }
  },
  {
    deep: true,
  },
);

watch(
  filtersApplied,
  (newFiltersApplied, oldFiltersApplied) => {
    if (props.isApi && !isEqual(newFiltersApplied, oldFiltersApplied)) {
      emits('api:request', getQueryParams());
    }
  },
  {
    deep: true,
  },
);

if (props.isApi) {
  emits('api:request', getQueryParams());
}
</script>
<template>
  <div class="FilterTableComponent position-relative p-3 emobg-border-1 emobg-border-color-ground emobg-background-color-white">
    <ui-loader
      v-if="isLoading"
      data-test-id="loader"
      absolute
    />
    <div class="d-flex align-items-center mb-2">
      <ui-button
        v-if="filters.length"
        :face="FACES.outline"
        :color="GRAYSCALE.inkLight"
        class="hydrated"
        @clickbutton="areFiltersVisible = !areFiltersVisible"
      >
        <ui-icon
          :icon="ICONS.settingsSlider"
          class="mr-1"
        />
        {{ areFiltersVisible ? 'Hide': 'Show' }} filters
        <ui-badge
          v-if="filtersCount"
          :color="COLORS.primary"
          :text="filtersCount"
          class="ml-1"
        />
      </ui-button>
      <div class="d-flex flex-grow-1 align-items-center justify-content-end">
        <TableSearchBoxComponent
          v-if="searchable"
          :model-value="search"
          @update:modelValue="setSearchDebounced"
        />
        <TablePaginatorComponent
          :total-pages="totalPages"
          :page="page"
          @update:page="setPage"
        />
        <ui-dropdown v-if="actionsMerged.length">
          <ui-icon
            slot="trigger"
            :icon="ICONS.optionsHorizontalFull"
            :color="GRAYSCALE.inkLight"
          />
          <ui-dropdown-actions
            slot="content"
            :actions.prop="actionsMerged"
            data-test-id="dropdown-actions"
          />
        </ui-dropdown>
      </div>
    </div>
    <div class="d-flex flex-row">
      <TableFiltersComponent
        v-if="areFiltersVisible"
        :filters-applied="filtersApplied"
        :filters="internalFilters"
        @update:filterApplied="setSingleFiltersApplied"
        @clear:filtersApplied="clearFiltersApplied"
      />

      <TableComponent
        v-bind="$attrs"
        :schema="schema"
        :data="dataToShow"
        class="flex-grow-1"
      />
    </div>
  </div>
</template>
