import has from 'lodash/has';
import isArray from 'lodash/isArray';
import isObject from 'lodash/isObject';
import size from 'lodash/size';
import { everyValue } from '@emobg/web-utils';
import { ERRORS } from '../const/errors';
import { daysNamesEnglish } from '../const/index';

export const validateSchedule = schedule => {
  if (!isObject(schedule)) {
    throw new Error(ERRORS.scheduleObject);
  }
  if (size(schedule) !== 7) {
    throw new Error(ERRORS.scheduleLength);
  }
  const areAllDaysPresent = everyValue(daysNamesEnglish, day => has(schedule, day));
  if (!areAllDaysPresent) {
    throw new Error(ERRORS.scheduleDaysMissing);
  }
  const areDaysArrays = everyValue(schedule, isArray);
  if (!areDaysArrays) {
    throw new Error(ERRORS.scheduleAllArray);
  }
};

