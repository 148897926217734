<script>
import { mapGetters, mapState } from 'vuex';
import DOMAINS_MODEL from '@domains/DOMAINS_MODEL';
import { getValue } from '@emobg/web-utils';

import ALGOLIA_INDEXES from '@/constants/algoliaIndexes';

export default {
  name: 'PermissionLink',
  props: {
    linkPermissions: {
      type: Array,
      default: () => [],
    },
    to: {
      type: Object,
      default: null,
    },
    href: {
      type: String,
      default: null,
    },
    target: {
      type: String,
      default: '_self',
    },
    text: {
      type: [String, Number],
      default: '',
    },
    classLink: {
      type: String,
      default: 'link',
    },
    classText: {
      type: String,
      default: '',
    },
    redirectHandler: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters(DOMAINS_MODEL.app.userAccount, [
      'hasPermissions',
    ]),

    ...mapState(DOMAINS_MODEL.app.userAccount, {
      activeOperatorId: state => state.operators.active.id,
    }),
  },

  methods: {
    async onClick() {
      const invoiceID = this.$props.href.split('/invoicing/invoices/')[1].split('/')[0];

      if (invoiceID) {
        const invoiceUUID = await this.getInvoiceUuidFromId(invoiceID);

        window.open(`/invoicing/invoices/${invoiceUUID}/summary/details`, 'target="_blank"');
      }
    },

    async getInvoiceUuidFromId(invoiceId) {
      const response = await this.$algolia.fetchIndex(ALGOLIA_INDEXES.invoices, {
        filters: `cs_operator_fk:${this.activeOperatorId} AND id:${invoiceId}`,
        attributesToRetrieve: ['uuid'],
      });

      return getValue(response, 'hits[0].uuid') || invoiceId;
    },
  },
};
</script>
<template>
  <RouterLink
    v-if="to && hasPermissions(linkPermissions)"
    :to="to"
    :target="target"
    :class="classLink"
  >
    <slot>
      {{ text }}
    </slot>
  </RouterLink>
  <a
    v-else-if="href && hasPermissions(linkPermissions) && !redirectHandler"
    :href="href"
    :target="target"
    :class="classLink"
  >
    <slot>
      {{ text }}
    </slot>
  </a>
  <a
    v-else-if="href && hasPermissions(linkPermissions) && redirectHandler"
    :class="classLink"
    @click="onClick"
  >
    <slot>
      {{ text }}
    </slot>
  </a>
  <span
    v-else
    :class="classText"
  >
    <slot>
      {{ text }}
    </slot>
  </span>
</template>
