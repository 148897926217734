<script>
import each from 'lodash/each';
import has from 'lodash/has';
import isInteger from 'lodash/isInteger';
import inRange from 'lodash/inRange';
import lowerCase from 'lodash/lowerCase';
import map from 'lodash/map';
import reduce from 'lodash/reduce';
import size from 'lodash/size';
import { everyValue } from '@emobg/web-utils';
import DayIntervals from './components/DayIntervals';
import InputInterval from './components/InputInterval';
import { isEnglishDayName, weekSchedule } from './utils';
import {
  dayNameToNumber,
  dayNumberToName,
  daysNamesEnglish,
  ERRORS,
} from './utils/const';

export default {
  name: 'WeekSchedule',
  components: {
    DayIntervals,
    InputInterval,
  },
  model: {
    prop: 'schedule',
    event: 'change',
  },
  props: {
    schedule: {
      type: Object,
      required: true,
    },
    minDuration: {
      type: [Number, String],
      default: 0,
    },
    isDisabled: {
      type: Boolean,
      default: false,
    },
    dayNames: {
      type: Object,
      required: true,
      validator(value) {
        return everyValue(daysNamesEnglish, day => has(value, day));
      },
    },
    errors: {
      type: Object,
      default: () => ERRORS,
    },
    firstDay: {
      type: [Number, String],
      default: 1,
      validator(value) {
        if (!value) {
          return true;
        }
        const asNumber = Number(value);
        if (isInteger(asNumber)) {
          return inRange(asNumber, 0, 7);
        }
        return isEnglishDayName(lowerCase(value));
      },
    },
  },
  data() {
    return {
      schedulerState: undefined,
      weekScheduler: undefined,
    };
  },
  computed: {
    orderedDays() {
      const firstDayAsNumber = parseInt(this.firstDay, 10);
      const firstDay = isInteger(firstDayAsNumber) ? firstDayAsNumber : dayNameToNumber[lowerCase(this.firstDay)];

      return Array.from(
        Array(7),
        (_value, index) => (index + firstDay) % 7,
      );
    },
    dayIntervals() {
      return reduce(
        this.orderedDays,
        (accumulator, dayNumber) => {
          const day = dayNumberToName[dayNumber];
          const intervals = this.schedule[day];
          if (size(intervals)) {
            const dayProps = {
              intervals,
              day,
              name: this.dayNames[day],
            };
            accumulator.push(dayProps);
          }
          return accumulator;
        },
        [],
      );
    },
    dayOptions() {
      return map(this.orderedDays, day => {
        const value = dayNumberToName[day];
        return {
          label: this.dayNames[value],
          value,
        };
      });
    },
  },
  created() {
    this.weekScheduler = weekSchedule(this.schedule);
    this.schedulerState = this.weekScheduler.get();
    this.$emit('min-consecutive-minutes', this.weekScheduler.getMinConsecutive());
  },
  methods: {
    addTimeIntervals({ interval, days }) {
      each(days, day => {
        this.weekScheduler.addInterval(interval, day);
      });
      this.emitAll();
    },
    deleteInterval(interval, day) {
      this.weekScheduler.removeInterval(interval, day);
      this.emitAll();
    },
    emitAll() {
      this.$emit('change', this.schedulerState);
      this.$emit('min-consecutive-minutes', this.weekScheduler.getMinConsecutive());
    },
  },
};
</script>
<template>
  <section class="WeekSchedule d-block mx-0 px-0 container">
    <InputInterval
      :day-options="dayOptions"
      :errors="errors"
      :week-scheduler="weekScheduler"
      class="WeekSchedule__InputInterval"
      @add-time-intervals="addTimeIntervals"
    />
    <DayIntervals
      v-for="dayInterval of dayIntervals"
      :key="dayInterval.day"
      class="WeekSchedule__DayIntervals"
      v-bind="dayInterval"
      :is-disabled="isDisabled"
      :min-duration="minDuration * 60"
      @delete-interval="deleteInterval($event, dayInterval.day)"
    />
  </section>
</template>
<style lang="scss">
  $field-width: 347px;

  .WeekSchedule {
    &__InputInterval,
    &__DayIntervals {
      &__day {
        width: $field-width !important;
      }

      &__time {
        width: calc($field-width - 6px) !important;
      }
    }
  }
</style>
