import CryptoJS from 'crypto-js';
import { logger } from '@emobg/web-utils';
export * from './algolia';
export * from './beamer';
export * from './auth0';
export * from './document';
export * from './numbers';
export * from './validations';
export * from './momentMixin';
export * from './store';
export * from './objects';
export * from './files';
export * from './images';

let envConfigs;

const Utils = {
  // Update the html document
  setupStyle({ title, style }) {
    // We update the title of the document
    document.title = title;

    // We Set the CSS style for the page
    if (style) {
      const link = document.createElement('link');
      link.href = style;
      link.type = 'text/css';
      link.rel = 'stylesheet';
      link.media = 'screen,print';
      document.getElementsByTagName('head')[0].appendChild(link);
    }
  },

  async loadEnvConfig() {
    if (envConfigs) {
      return envConfigs;
    }

    try {
      const response = await fetch('/middleware/config');

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const data = await response.json();
      const bytes = CryptoJS.AES.decrypt(data.env, 'e3b0c44298fc1c149afbf4c8996fb92427ae41e4649b934ca495991b7852b855');
      const decryptedText = bytes.toString(CryptoJS.enc.Utf8);
      data.env = decryptedText && JSON.parse(decryptedText);
      envConfigs = data;

      return envConfigs;
    } catch (error) {
      logger.message('Error while loading environmental data or while encryption.', 'error');
      logger.message(error, 'error');
      return {};
    }
  },

  async setToken({ accessToken }) {
    try {
      return await fetch('/middleware/token', {
        method: 'POST',
        body: JSON.stringify({ accessToken }),
      });
    } catch (error) {
      logger.message('Error while loading environmental data or while encryption.', 'error');
      logger.message(error, 'error');
      return {};
    }
  },
};

export default Utils;
