<script>
import defaults from 'lodash/defaults';
import defaultsDeep from 'lodash/defaultsDeep';
import each from 'lodash/each';
import get from 'lodash/get';
import map from 'lodash/map';
import set from 'lodash/set';
import { MuiModal } from '@emobg/motion-ui/v1';
import GenericModalFooter from './components/GenericModalFooter/GenericModalFooterComponent';
import GenericModalBody from './components/GenericModalBody/GenericModalBodyComponent';

export default {
  name: 'GenericModalComponent',
  components: {
    GenericModalBody,
    GenericModalFooter,
    MuiModal,
  },
  inheritAttrs: false,
  props: {
    header: {
      type: Object,
      default: () => ({}),
    },
    buttons: {
      type: Array,
      default: () => [],
    },
    isAlertSticky: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    headerComputed() {
      return this.header
        ? defaults(
          {}, this.header,
          {
            backColor: this.COLORS.primary,
            class: 'pl-3',
          },
        )
        : this.header;
    },
    buttonsChecked() {
      return this.createButtons(this.buttons);
    },
  },
  created() {
    this.defaultButtonOptions = {
      attributes: {
        disabled: false,
        loading: false,
        size: this.SIZES.small,
      },
      text: '',
    };
  },
  methods: {
    get,
    createButtons(buttonsToTransform) {
      return map(buttonsToTransform, button => {
        const buttonWithDefaults = defaultsDeep({}, button, this.defaultButtonOptions);
        const clickListener = get(buttonWithDefaults, 'listeners.click');

        if (clickListener) {
          buttonWithDefaults.listeners.clickbutton = async () => {
            this.setButtonToLoading(buttonWithDefaults);
            await clickListener();
            this.resetButtonsState();
          };

          delete buttonWithDefaults.listeners.click;
        }
        return buttonWithDefaults;
      });
    },
    setButtonToLoading(button) {
      each(this.buttonsChecked, buttonToDisable => {
        set(buttonToDisable, 'attributes.disabled', true);
      });
      set(button, 'attributes.loading', true);
    },
    resetButtonsState() {
      each(this.buttonsChecked, button => {
        set(button, 'attributes.disabled', false);
        set(button, 'attributes.loading', false);
      });
    },
  },
};
</script>

<template>
  <MuiModal
    v-bind="$attrs"
    :header="headerComputed"
    class="GenericModalComponent"
    is-open
    data-test-id="generic_modal"
    v-on="$listeners"
    @modal-closed="$emit('closeModal')"
  >
    <template #header>
      <slot name="header" />
    </template>
    <template slot="body">
      <div
        v-if="!!$slots.alerts"
        :class="[
          'GenericModalComponent__alerts',
          { 'GenericModalComponent__alerts--sticky': isAlertSticky },
        ]"
      >
        <slot name="alerts" />
      </div>
      <slot />
      <GenericModalBody
        v-if="!!$slots.body"
        data-test-id="body"
      >
        <slot name="body" />
      </GenericModalBody>
    </template>
    <GenericModalFooter
      v-if="buttons.length || !!$slots.footer"
      slot="footer"
      data-test-id="footer"
    >
      <slot name="footer">
        <div class="d-flex justify-content-center justify-content-sm-end align-items-center">
          <ui-button
            v-for="(button, index) in buttonsChecked"
            :key="`button-${index}`"
            :class="[
              get(button, 'attributes.class', ''),
              'my-1',
              {
                'ml-2': index !== 0
              }
            ]"
            :data-test-id="`${index}-button`"
            v-bind="button.attributes"
            v-on="button.listeners"
          >
            {{ button.text }}
          </ui-button>
        </div>
      </slot>
    </GenericModalFooter>
  </MuiModal>
</template>
