var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "MuiModal",
    _vm._g(
      _vm._b(
        {
          staticClass: "GenericModalComponent",
          attrs: {
            header: _vm.headerComputed,
            "is-open": "",
            "data-test-id": "generic_modal",
          },
          on: {
            "modal-closed": function ($event) {
              return _vm.$emit("closeModal")
            },
          },
          scopedSlots: _vm._u(
            [
              {
                key: "header",
                fn: function () {
                  return [_vm._t("header")]
                },
                proxy: true,
              },
            ],
            null,
            true
          ),
        },
        "MuiModal",
        _vm.$attrs,
        false
      ),
      _vm.$listeners
    ),
    [
      _c(
        "template",
        { slot: "body" },
        [
          !!_vm.$slots.alerts
            ? _c(
                "div",
                {
                  class: [
                    "GenericModalComponent__alerts",
                    {
                      "GenericModalComponent__alerts--sticky":
                        _vm.isAlertSticky,
                    },
                  ],
                },
                [_vm._t("alerts")],
                2
              )
            : _vm._e(),
          _vm._t("default"),
          !!_vm.$slots.body
            ? _c(
                "GenericModalBody",
                { attrs: { "data-test-id": "body" } },
                [_vm._t("body")],
                2
              )
            : _vm._e(),
        ],
        2
      ),
      _vm.buttons.length || !!_vm.$slots.footer
        ? _c(
            "GenericModalFooter",
            {
              attrs: { slot: "footer", "data-test-id": "footer" },
              slot: "footer",
            },
            [
              _vm._t("footer", function () {
                return [
                  _c(
                    "div",
                    {
                      staticClass:
                        "d-flex justify-content-center justify-content-sm-end align-items-center",
                    },
                    _vm._l(_vm.buttonsChecked, function (button, index) {
                      return _c(
                        "ui-button",
                        _vm._g(
                          _vm._b(
                            {
                              key: `button-${index}`,
                              class: [
                                _vm.get(button, "attributes.class", ""),
                                "my-1",
                                {
                                  "ml-2": index !== 0,
                                },
                              ],
                              attrs: { "data-test-id": `${index}-button` },
                            },
                            "ui-button",
                            button.attributes,
                            false
                          ),
                          button.listeners
                        ),
                        [_vm._v(" " + _vm._s(button.text) + " ")]
                      )
                    }),
                    1
                  ),
                ]
              }),
            ],
            2
          )
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }