import Vue from 'vue';
import Vuex from 'vuex';

import App from './modules/App/AppModule';
import Setup from './modules/Setup/SetupModule';

let store = null;

/**
 * Exports a function to create store to be used when it's needed.
 */
const createStore = () => {
  /** Make sure we return the same store everytime. */
  if (store && store.hasModule('App')) {
    return store;
  }

  Vue.use(Vuex);
  store = new Vuex.Store({
    modules: {
      App,
      Setup,
    },
  });

  import('./modules/App/Domains/DomainsModule').then(Domains => {
    store.registerModule('Domains', Domains.default);
  });

  return store;
};

export default createStore;
