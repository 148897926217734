import get from 'lodash/get';
import pickBy from 'lodash/pickBy';
import startsWith from 'lodash/startsWith';

import 'core-js/stable';
import 'regenerator-runtime/runtime';
// app
import Vue from 'vue';
import { sync } from 'vuex-router-sync';
import Router from 'vue-router';

import { authService, ldClient } from '@emobg/access-utils';
import { ENV as ENV_NAMES } from '@emobg/web-utils';

import { applyPolyfills, defineCustomElements } from '@emobg/web-components/loader';

import * as Sentry from '@sentry/vue';

import { API_VERSIONS, MuiAlgoliaPlugin } from '@emobg/motion-ui';

import permissionsMixin from '@domains/Main/mixins/permissionsMixin';

import googleMaps from '@/components/GoogleMap/GoogleMapsPlugin';
import NotificationsPlugin from '@/store/modules/App/Messages/Notifications/NotificationsPlugin';
import PhrasesPlugin from '@/phrases/PhrasesPlugin';

import HttpClient from './api';

import { GlobalNotificationMixin } from './utils/mixins';
import Utils, { logoutAuth0, momentMixin, setupAuth } from './utils';
import { router as routerBuilder } from './router';
import IndexView from './index';
import createStore from './store';

import componentOptionsMixin from './mixins/componentOptionsMixin';

const backOfficeVersion = require('../package.json').version;

Vue.mixin(momentMixin); // moment as 'moment'
Vue.mixin(permissionsMixin); // User permissions
Vue.mixin(componentOptionsMixin); // constants like COLORS, SIZES, FALLBACK_MESSAGE...
Vue.use(Router);

applyPolyfills().then(() => {
  defineCustomElements();
});

/**
 * initialises the Back Office
 */

async function init() {
  const envConfig = await Utils.loadEnvConfig();
  const {
    template,
    company,
    title,
    style,
    assets,
    env: ENV,
  } = envConfig;

  /**
  * This config needs to be before store creation so that it gets detected and is available in vuex tab inside vue devtools.
  * Reference: https://github.com/vuejs/vue-devtools/issues/405
  */
  Vue.config.devtools = ENV.isDebugEnabled;

  const store = createStore();

  const router = routerBuilder({ ENV });
  // so we can use authService in App to login and logout
  await setupAuth(ENV);
  if (authService.instance.isAuthenticated()) {
    await authService.instance.renewTokens();
  } else {
    logoutAuth0();
  }
  const accessToken = get(authService, 'instance.accessToken');

  sync(store, router);

  Utils.setupStyle({
    title,
    style,
  });

  store.commit('Setup/updateSetup', {
    template,
    company,
    title,
    style,
    assets,
    ENV,
  });

  await Utils.setToken({ accessToken });
  Vue.use(HttpClient, { ENV });

  const headers = () => {
    const {
      accessToken: Authorization,
      activeOperatorId,
    } = get(authService, 'instance', {});
    return {
      Authorization,
      'Active-Operator': activeOperatorId || null,
    };
  };

  // TODO: CF-541 - remove FF once we are sure V3 api works correctly
  const launchDarklyApiKey = get(envConfig, 'env.launchDarkly');
  const launchDarklyInstance = ldClient(launchDarklyApiKey, { key: '' });

  launchDarklyInstance.on('ready', () => {
    const featureFlags = launchDarklyInstance.allFlags();
    const msboFeatureFlags = pickBy(featureFlags, (_value, key) => startsWith(key, 'msbo'));
    const isV3AlgoliaAPI = get(msboFeatureFlags, 'msbo-algolia-api-version');

    Vue.use(MuiAlgoliaPlugin, {
      ...ENV.algolia,
      API_VERSION: isV3AlgoliaAPI ? API_VERSIONS.getV3 : API_VERSIONS.postV2,
      headers,
    });
  });

  Vue.use(googleMaps, {
    libraries: ['geometry', 'places', 'drawing'],
    key: ENV.googleMapsKey,
  });
  Vue.use(NotificationsPlugin, store);

  Vue.use(PhrasesPlugin);

  // Method to launch the two messages from error responses from backend
  Vue.mixin(GlobalNotificationMixin);

  document.body.className = document.body.className.replace('no_app', '');

  const { mode } = ENV;
  // Sentry init
  if (mode !== ENV_NAMES.local) {
    Sentry.init({
      dsn: 'https://8c4e9fef69ce7e886c6e6e0ccf0d6a6e@o274321.ingest.us.sentry.io/1489961',
      debug: mode !== ENV_NAMES.production,
      environment: mode,
      release: `frontend-msbo@${backOfficeVersion}`,
      integrations: [
        Sentry.browserTracingIntegration({ router }),
      ],
    });
  }
  // End Sentry init

  new Vue({
    el: '#app',
    router,
    store,
    render: h => h(IndexView),
  }).$mount('#app');
}
init();
