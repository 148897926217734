export const carsharing = {
  endInvalid: 'carsharing.interval_time_unit_end',
  maximumDuration: 'carsharing.maximum_duration_exception',
  minimumDuration: 'carsharing.minimum_duration_exception',
  minutesBeforeNow: 'carsharing.minutes_before_current_time',
  overbooked: 'carsharing.overbooking',
  overlap: 'carsharing.booking_overlap',
  startInvalid: 'carsharing.interval_time_unit_start',
  tariffNotFound: 'carsharing.tariff_not_found',
  userNotActivated: 'carsharing.user_not_activated',
  notInAnticipationTime: 'carsharing.booking_not_in_anticipation_time',
  chassisAlreadyExists: 'fleet.vehicle_chassis_number_already_exists',
  identityCardRequired: 'identity_card_required',
  drivingLicenseNotValidForWhileBooking: 'driving_license_not_valid_for_whole_booking',
  drivingLicenseExpired: 'driving_license_expired',
  drivingLicenseNotValid: 'driving_license_not_valid',
  drivingLicenseNotValidForThisCompany: 'driving_license_not_valid_for_this_company',
};
